import React, { useState, useEffect } from 'react';
import 'chart.js/auto';
import { ref, child, get } from 'firebase/database';
import { Line } from 'react-chartjs-2';
import db from './db';

const sortTemps = (val) => {
  const sorted = {};
  Object.keys(val)
    .sort((d1, d2) => new Date(d1) - new Date(d2))
    .forEach((k) => {
      sorted[k] = val[k];
    });
  console.log(sorted);
  return sorted;
}

const App = () => {
  const [labels, setLabels] = useState([]);
  const [batTemps, setBat] = useState([]);
  const [localTemps, setLocal] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const dbRef = ref(db);
    get(child(dbRef, '/'))
    .then((s) => {
      const val = sortTemps(s.val());
      setLabels(Object.keys(val));
      const bts = [];
      const lts = [];
      Object.values(val).forEach(t => {
        bts.push(t.bat);
        lts.push(t.local);
      });
      setBat(bts);
      setLocal(lts);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <header>
        <h1 className="main">Docu3C Interview Problem Statement #3</h1>
        <h3 className="sub">Determining your Ambient Room Temperature</h3>
      </header>
      
      <div className="chart">
      { loading ? 'Loading...' : (
        <Line
          data={{
            labels,
            datasets: [
              {
                label: 'Battery Temperature',
                data: batTemps,
                borderColor: 'rgba(29, 78, 216, 1)'
              },
              {
                label: 'Local temperature',
                data: localTemps,
                borderColor: 'rgba(201, 40, 40, 1)'
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              yAxis: {
                min: 20,
                max: 45,
                title: {
                  display: true,
                  text: 'Local Temperature',
                  font: {
                    size: 14,
                  },
                },
              },
              xAxis: {
                title: {
                  display: true,
                  text: 'Battery Temperature',
                  font: {
                    size: 14,
                  },
                },
              },
            },
          }}
        />
        )}
      </div>
    </>
  );
}

export default App;
