import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC7DqnWirooWHOte4nxGLsNGsGiOsNQVJ4",
  authDomain: "docu3c-viz.firebaseapp.com",
  databaseURL: "https://docu3c-viz-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "docu3c-viz",
  storageBucket: "docu3c-viz.appspot.com",
  messagingSenderId: "471630721484",
  appId: "1:471630721484:web:cbbd60ada035ff2f3d2d69"
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);

export default db;